import qs from 'qs';

import { PUZZLES, STAND } from './constants/content';
import { CURRENCIES } from './constants/inner';

export function openLink(link) {
  window.open(link, '_blank');
}

export function disableBodyScroll() {
  const body = document.querySelector('body');

  body.classList.add('open-modal');
}

export function enableBodyScroll() {
  const body = document.querySelector('body');

  body.classList.remove('open-modal');
}

export function required(value) {
  return value && value.trim() ? '' : 'Заполните поле!';
}

export function getOrderTotal(orderData, region) {
  return Object.entries(orderData).reduce((acc, [key, next]) => {
    const newAcc =
      acc +
      (PUZZLES[key].discountPrice[region] * next.quantity +
        (next.withStand ? STAND.price[region] : 0));

    return newAcc;
  }, 0);
}

export function flatten(input) {
  const stack = [...input];
  const res = [];
  while (stack.length) {
    const next = stack.pop();
    if (Array.isArray(next)) {
      stack.push(...next);
    } else {
      res.push(next);
    }
  }

  return res.reverse();
}

export function getCurrency(region) {
  return CURRENCIES[region] || CURRENCIES.BY;
}

export function openWindowWithKey(key) {
  const { search, origin } = window.location;
  const { region } = qs.parse(search.slice(1));

  const newLocation = `${origin}/${region ? `?region=${region}&` : '?'}${key}=true`;
  window.open(newLocation, '_self');
}
