<script>
  import { PUZZLES } from '../../constants/content';
  import { BUTTON_TYPES, MODAL_IDS } from '../../constants/inner';
  import { openModal } from '../../store/modals';
  import region from '../../store/region';
  import { getCurrency, openWindowWithKey } from '../../utils';
  import Button from '../common/Button.svelte';
  import PuzzleLot from '../common/PuzzleLot.svelte';

  export let windowWidth;
  let visibleElementsLength = 4;
  const puzzles = Object.entries(PUZZLES);

  $: visibleElements = puzzles.slice(0, visibleElementsLength);

  function onInfoClick(key) {
    openModal(MODAL_IDS.PUZZLE_INFO, { puzzleKey: key });
  }

  function onBuyClick(key) {
    localStorage.setItem('active-puzzle', key);
    openWindowWithKey('purchase');
  }

  function onMoreClick() {
    visibleElementsLength += 8;
  }
</script>

<section id="catalog">
  <h1>Каталог</h1>
  <div
    style={`grid-template-rows: repeat(${Math.ceil(
      visibleElements.length / (windowWidth < 600 ? 2 : 4),
    )}, minmax(110px, 1fr));`}
    class="container"
  >
    {#each visibleElements as [key, { offer_id, specs, images, imagesSmall, video, price, discountPrice, ...puzzle }] (key)}
      <PuzzleLot
        {...puzzle}
        price={`${price[$region]} ${getCurrency($region)}`}
        discountPrice={`${discountPrice[$region]} ${getCurrency($region)}`}
        image={windowWidth < 600 ? imagesSmall[0].src : images[0].src}
        imageAlt={windowWidth < 600 ? imagesSmall[0].alt : images[0].alt}
        hoverImage={windowWidth < 600 ? imagesSmall[1].src : images[1].src}
        hoverImageAlt={windowWidth < 600 ? imagesSmall[1].alt : images[1].alt}
        {onBuyClick}
        {onInfoClick}
      />
    {/each}
  </div>
  <div class="button">
    {#if visibleElementsLength < puzzles.length}
      <Button ref="more-button" on:click={onMoreClick} type={BUTTON_TYPES.CIRCLE}>
        Больше товаров
      </Button>
    {/if}
  </div>
</section>

<style lang="scss">
  @import '../../variables.scss';

  section {
    height: 100%;
    width: calc(100% - 28rem + 15px);
    padding: 20px 14rem;
  }

  h1 {
    padding-bottom: 7rem;
    color: $accent-color;
  }

  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-gap: 30px;
    justify-items: center;
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  :global([ref='more-button']) {
    @extend .font-24 !optional;

    color: $accent-color;
    background-color: $light-color;
    height: 20rem;
    width: 20rem;
  }

  @media screen and (max-width: 600px) {
    section {
      height: 100%;
      width: calc(100% - 8.4rem);
      padding: 0px 4.2rem;
    }
    h1 {
      padding: 3rem 0;
      color: $accent-color;
    }

    :global([ref='more-button']) {
      height: 120px;
      width: 120px;
    }

    .container {
      grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
      grid-gap: 2rem;
    }
  }
</style>
