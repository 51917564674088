<script>
  import Navigation from './Navigation.svelte';
  import BuyPuzzle from './BuyPuzzle/BuyPuzzle.svelte';
  import PuzzleInfo from './PuzzleInfo.svelte';
  import PrivacyPolicy from './PrivacyPolicy.svelte';
  import Success from './Success.svelte';

  import modals from '../../store/modals';
  import { MODAL_IDS } from '../../constants/inner';

  export let windowWidth;
</script>

<div>
  <Navigation {windowWidth} visible={$modals.visible[MODAL_IDS.NAVIGATION]} />
  <BuyPuzzle
    {windowWidth}
    visible={$modals.visible[MODAL_IDS.BUY]}
    {...$modals.payloads[MODAL_IDS.BUY]}
  />
  <PuzzleInfo
    {windowWidth}
    visible={$modals.visible[MODAL_IDS.PUZZLE_INFO]}
    {...$modals.payloads[MODAL_IDS.PUZZLE_INFO]}
  />
  <PrivacyPolicy visible={$modals.visible[MODAL_IDS.PRIVACY]} />
  <Success visible={$modals.visible[MODAL_IDS.SUCCESS]} />
</div>

<style lang="scss">
  div {
    height: 100%;
    position: fixed;
    top: 0px;
    display: flex;
    align-items: flex-start;
  }
</style>
