export const BUTTON_TYPES = {
  ACCENT: 'ACCENT',
  ACCENT_EXTENDED: 'ACCENT_EXTENDED',
  LIGHT: 'LIGHT',
  SECONDARY: 'SECONDARY',
  TRANSPARENT: 'TRANSPARENT',
  CIRCLE: 'CIRCLE',
  ICON: 'ICON',
};

export const PAGER_TYPES = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL',
};

export const MODAL_IDS = {
  BUY: 'BUY',
  NAVIGATION: 'NAVIGATION',
  PRIVACY: 'PRIVACY',
  PUZZLE_INFO: 'PUZZLE_INFO',
  SUCCESS: 'SUCCESS',
};

export const MODAL_TYPES = {
  FULLSCREEN: 'FULLSCREEN',
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
};

export const COLORS = {
  LIGHT: '#fff6ee',
  PRIMARY: '#d1af8f',
  ACCENT: '#40261d',
  DISABLED: '#998174',
};

export const REGIONS = {
  RU: 'RU',
  BY: 'BY',
};

export const CURRENCIES = {
  [REGIONS.RU]: 'RUB',
  [REGIONS.BY]: 'BYN',
};
