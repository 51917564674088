<script>
  import { fly, fade } from 'svelte/transition';
  import cn from 'classnames';

  import { BUTTON_TYPES, COLORS, MODAL_TYPES } from '../../constants/inner';
  import Button from './Button.svelte';
  import Close from '../icons/Close.svelte';

  export let type = MODAL_TYPES.FULLSCREEN;
  export let closeColor = COLORS.LIGHT;
  export let close;
  let modalWindow;

  function handleKeydown(event) {
    if (event.key === 'Escape') {
      event.preventDefault();
      close();
    }
    if (event.key === 'Tab') {
      const nodes = modalWindow.querySelectorAll('*');
      const tabbable = Array.from(nodes).filter((node) => node.tabIndex >= 0);
      let index = tabbable.indexOf(document.activeElement);
      if (index === -1 && event.shiftKey) index = 0;
      index += tabbable.length + (event.shiftKey ? -1 : 1);
      index %= tabbable.length;
      tabbable[index].focus();
      event.preventDefault();
    }
  }
  const handleOuterClick = (event) => {
    if (event.target === modalWindow) {
      event.preventDefault();
      close();
    }
  };
</script>

<svelte:window on:keydown={handleKeydown} />

<div
  on:click={handleOuterClick}
  bind:this={modalWindow}
  role="dialog"
  aria-modal="true"
  class="container"
  transition:fade
>
  <div transition:fly class={cn('content', { [type.toLowerCase()]: true })}>
    <slot />
    <Button ref="modal-close" on:click={close} type={BUTTON_TYPES.ICON}>
      <Close color={closeColor} />
    </Button>
  </div>
</div>

<style lang="scss">
  @import '../../variables.scss';

  .container {
    height: 100%;
    width: 100vw;
    background: rgba(0, 0, 0, 0.16);
    display: flex;
  }

  .content {
    position: relative;
  }

  .fullscreen {
    height: 100%;
    width: 100%;

    :global([ref='modal-close']) {
      right: 63px;
      top: 45px;
    }
  }

  .large,
  .medium {
    border-radius: 50px;
    background-color: $light-color;
    margin: auto;
    padding: 30px;
    box-shadow: 0px 0px 10px;

    :global([ref='modal-close']) {
      right: 57px;
      top: 30px;
    }
  }

  .large {
    height: 73.5rem;
    width: 121rem;
  }

  .medium {
    height: 50.5rem;
    width: 81rem;
  }

  :global([ref='modal-close']) {
    position: absolute;
    width: 30px;
    // height: 30px;
  }

  @media screen and (max-width: 850px) {
    .fullscreen,
    .large,
    .medium {
      :global([ref='modal-close']) {
        right: 63px;
        top: 45px;
      }
    }

    .fullscreen,
    .large {
      height: 100%;
      width: 100%;
      border-radius: 0px;
    }

    .large {
      width: calc(100% - 60px);
      height: calc(100% - 60px);
    }

    .medium {
      height: 50%;
      width: 66%;
    }
  }

  @media screen and (max-width: 600px) {
    .content {
      :global([ref='modal-close']) {
        top: 1.5rem;
        right: 2.2rem;
        width: 20px;
      }
    }

    .large {
      padding: 30px 10px;
      width: calc(100% - 20px);
      height: calc(100% - 60px);
    }

    .medium {
      height: 50%;
      width: 66%;

      :global([ref='modal-close']) {
        top: 2.5rem;
        right: 2.5rem;
      }
    }
  }
</style>
