<script>
  export let color;
</script>

<svg width="31" height="31" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <line
    x1="3.41421"
    y1="2"
    x2="37"
    y2="35.5858"
    stroke={color}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <line
    x1="1"
    y1="-1"
    x2="48.4975"
    y2="-1"
    transform="matrix(-0.707107 0.707107 0.707107 0.707107 37 2)"
    stroke={color}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
