<script>
  import cn from 'classnames';

  import { PAGER_TYPES } from '../../constants/inner';

  export let type = PAGER_TYPES.HORIZONTAL;
  export let total;
  export let active = 0;
</script>

<style lang="scss">
  @import '../../variables.scss';

  .container {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .main {
    margin: auto 0;
    background-color: rgba(250, 233, 218, 0.5);
  }
  .pager {
    position: absolute;
    font-weight: 800;
  }
  .divider {
    height: 2px;
    width: 100%;
    margin: 10px 0px;
  }

  .vertical {
    height: 100%;
    padding: 0 20px;
    .main {
      height: 100%;
      width: 2px;
    }

    .pager {
      background-color: $primary-color;
      color: $light-color;
      padding: 3rem;
      top: 10rem;
      left: -27px;
      .divider {
        background-color: $light-color;
      }
    }
  }

  .horizontal {
    padding: 20px 0;
    width: 100%;
    height: 20px;
    .main {
      width: 100%;
      height: 2px;
      background-color: $disabled-color;
    }
    .pager {
      background-color: $light-color;
      color: $accent-color;
      padding: 30px 5.6rem;
      top: -4.5rem;
      left: 12rem;
      .divider {
        background-color: $accent-color;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .horizontal {
      .pager {
        padding: 0px 12px;
        top: 0px;
        left: 40px;
      }
    }
  }
</style>

<div class={cn('container', { [type.toLowerCase()]: true })}>
  <div class="main" />
  <div class="pager font-24">
    <span>{`0${active}`}</span>
    <div class="divider" />
    <span>{`0${total}`}</span>
  </div>
</div>
