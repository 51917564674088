<script>
  import { LINKS } from '../../constants/content';

  export let windowWidth;
</script>

<style lang="scss">
  @import '../../variables.scss';

  section {
    width: calc(100% - 30rem);
    padding: 40px 15rem 120px 15rem;
  }
  h1 {
    color: $accent-color;
  }
  h1 {
    margin-bottom: 8rem;
  }

  p {
    font-weight: 500;
    text-align: center;
    margin-bottom: 3rem;
  }

  .container {
    max-width: 85rem;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }

 

  @media screen and (max-width: 600px) {
    .container{
    max-width: none;
    margin-left: 0px;
    margin-right: 0px;
    }
    h1 {
      margin-bottom: 44px;
    }

    a {
      color: $accent-color;
    }

    p {
      text-align: center;
    }
    section {
      width: calc(100% - 18px);
      padding: 2rem 1rem 4rem 1rem;
    }
  }
</style>

<section id="commercial">
  <h1>Коммерческое предложение</h1>

  <div class="container">
    <p>
      Мы работаем с юридические лицами, вы можете оплатить по безналичному расчету. Изготавливаем
      индивидуальные пазлы по вашим пожеланиям.
    </p>
    <p>
      Изготавливаем пазлы в подарочных коробках для корпоративных подарков. По всем вопросам
      обращаться на почту touchwoodby@mail.ru
    </p>

    {#if windowWidth <= 600}
      <a href={LINKS.PHONE} class="phone font-18">+375 29 210 2999</a>
    {:else}
      <p>+375292102999</p>
    {/if}
  </div>
</section>
