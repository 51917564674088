<script>
  import { beforeUpdate, onDestroy } from 'svelte';

  import { closeModal } from '../../store/modals';
  import { COLORS, MODAL_IDS, MODAL_TYPES } from '../../constants/inner';
  import Modal from '../common/Modal.svelte';
  import SuccessOrder from '../icons/SuccessOrder.svelte';

  export let visible;
  let timeout = null;

  beforeUpdate(() => {
    if (visible) {
      timeout = setTimeout(() => closeModal(MODAL_IDS.SUCCESS), 4000);
    }
  });

  onDestroy(() => {
    clearTimeout(timeout);
  });
</script>

{#if visible}
  <Modal
    close={() => closeModal(MODAL_IDS.SUCCESS)}
    closeColor={COLORS.ACCENT}
    type={MODAL_TYPES.MEDIUM}
  >
    <div class="container">
      <SuccessOrder className="success-icon" />

      <h2>Спасибо за заказ</h2>
      <p>Наш менеджер свяжется с Вами в течении 7 минут</p>
    </div>
  </Modal>
{/if}

<style lang="scss">
  @import '../../variables.scss';

  .container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  :global(.success-icon) {
    width: 22.5rem;
    height: 22.5rem;
  }

  h2 {
    color: $accent-color;
    margin: 5rem 0px;
  }

  p {
    color: $accent-color;
  }

  @media screen and (max-width: 600px) {
    :global(.success-icon) {
      width: 9rem;
      height: 9rem;
    }

    h2 {
      color: $accent-color;
      margin: 3rem 0px;
    }
  }
</style>
