<script>
  import { getCurrency, getOrderTotal, openWindowWithKey, required } from '../../../utils';
  import Field from '../../common/Field.svelte';
  import Button from '../../common/Button.svelte';
  import { BUTTON_TYPES } from '../../../constants/inner';
  import Tooltip from '../../common/Tooltip.svelte';
  import { PUZZLES, STAND } from '../../../constants/content';
  import apiService from '../../../apiService';
  import region from '../../../store/region';

  export let windowWidth;
  export let orderData;
  let isProcessing = false;
  let name = '';
  let phone = '';

  $: total = getOrderTotal(orderData, $region);
  $: actualOrder = Object.entries(orderData).filter(([, { quantity }]) => quantity);

  async function onSubmit() {
    try {
      isProcessing = true;

      const actualProducts = actualOrder.map(([, { withStand, price, ...data }]) => ({
        ...data,
        price,
      }));
      const standsCount = actualOrder.filter(([, { withStand }]) => withStand).length;

      if (standsCount) {
        actualProducts.push({
          offer_id: STAND.offer_id,
          price: STAND.price[$region],
          quantity: standsCount,
        });
      }

      Promise.allSettled([
        apiService.sendOrderEmail({ name, phone, total, order: actualOrder }),
        apiService.sendOrderToCrm({
          name,
          phone,
          products: actualProducts,
        }),
      ]).then(() =>
        setTimeout(() => {
          openWindowWithKey('success');
        }, 300),
      );
    } catch (e) {
      isProcessing = false;
    }
  }
</script>

<div class="container">
  {#if windowWidth < 600}
    <h3 class="title">Сумма заказа</h3>
    <div class="field">
      <div class="total">
        <h3 class="subtitle">{total} {getCurrency($region)}</h3>
        <Tooltip error={!actualOrder.length}>
          {#if actualOrder.length}
            {#each actualOrder as [key, { quantity, withStand }] (key)}
              <div class="check-puzzle">
                <span class="check-puzzle-name"
                  >{PUZZLES[key].name}
                  x
                  {quantity}
                  -
                  {PUZZLES[key].discountPrice[$region] * quantity +
                    (withStand ? STAND.price[$region] : 0)}
                  {getCurrency($region)}</span
                >
                {#if withStand}<span class="check-puzzle-count">+ подставка</span>{/if}
              </div>
            {/each}
          {:else}
            <span class="check-puzzle-name">Добавьте пазл в заказ!</span>
          {/if}
        </Tooltip>
      </div>
    </div>
  {/if}
  <h3 class="title">Оформите Ваш заказ</h3>
  <div class="field">
    <Field name="name" placeholder="Имя*" bind:value={name} validate={required} />
  </div>
  <div class="field">
    <Field name="phone" placeholder="Телефон*" bind:value={phone} validate={required} />
  </div>
  {#if windowWidth > 600}
    <h3 class="title">Сумма заказа</h3>
    <div class="field">
      <div class="total">
        <h3 class="subtitle">{total} {getCurrency($region)}</h3>
        <Tooltip error={!actualOrder.length}>
          {#if actualOrder.length}
            {#each actualOrder as [key, { quantity, withStand }] (key)}
              <div class="check-puzzle">
                <span class="check-puzzle-name"
                  >{PUZZLES[key].name}
                  x
                  {quantity}
                  -
                  {PUZZLES[key].discountPrice[$region] * quantity +
                    (withStand ? STAND.price[$region] : 0)}
                  {getCurrency($region)}</span
                >
                {#if withStand}<span class="check-puzzle-count">+ подставка</span>{/if}
              </div>
            {/each}
          {:else}
            <span class="check-puzzle-name">Добавьте пазл в заказ!</span>
          {/if}
        </Tooltip>
      </div>
    </div>
  {/if}

  <div class="field control">
    <Button
      disabled={!(total && name && phone) || isProcessing}
      loading={isProcessing}
      ref="submit"
      type={BUTTON_TYPES.ACCENT}
      on:click={onSubmit}
    >
      Отправить
    </Button>
    <p>- Укажите свои данные и наш менеджер свяжется с Вами в течении 7 минут.</p>
  </div>
</div>

<style lang="scss">
  @import '../../../variables.scss';

  p {
    @extend .font-18 !optional;

    color: $disabled-color;
    font-weight: bold;
    text-align: start;
    max-width: 46rem;
  }

  .title,
  .subtitle {
    color: $accent-color;
    margin-bottom: 3rem;
    max-width: 30rem;
    text-align: start;
  }

  .subtitle {
    font-weight: 500;
  }

  .field {
    margin-bottom: 2.6rem;
  }

  .total {
    display: flex;
    align-items: flex-start;
  }

  .error-tooltip {
    @extend .font-14 !optional;
  }

  :global([ref='submit']) {
    margin-bottom: 3rem;
  }
  .check-puzzle {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  .check-puzzle:last-child {
    margin-bottom: 0px;
  }
  .check-puzzle-name,
  .check-puzzle-count {
    @extend .font-18 !optional;

    color: $accent-color;
  }

  .check-puzzle-name {
    font-weight: 800;
  }

  .check-puzzle-count {
    font-weight: 500;
  }

  @media screen and (max-width: 850px) {
    p,
    h3,
    .title,
    .subtitle {
      text-align: center;
      max-width: 100%;
    }

    .field {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 600px) {
    .title,
    .subtitle,
    .field {
      margin-bottom: 2rem;
    }

    h3.subtitle {
      margin-bottom: 0px;
    }

    .control {
      display: flex;
      flex-direction: column-reverse;
    }

    :global([ref='submit']) {
      margin-bottom: 7rem;
      margin-top: 2rem;
    }
  }
</style>
