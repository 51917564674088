<script>
  import { onDestroy } from 'svelte';

  const now = new Date();
  const day = 86400000;
  const sec = 1000;
  const min = 60;
  const hr = 24;
  let timer = day - (now.getTime() - new Date(now.toDateString()).getTime());
  const interval = setInterval(() => {
    const newTimerValue = timer - 1000;

    timer = newTimerValue <= 0 ? 86400000 : newTimerValue;
  }, 1000);
  let isActive = true;

  $: h = Math.floor((timer / (sec * min * min)) % hr);
  $: m = Math.floor((timer / sec / min) % min);
  $: s = Math.floor((timer / sec) % min);
  $: className = isActive ? 'active' : '';

  onDestroy(() => clearInterval(interval));
</script>

<div
  class={`container ${className}`}
  on:click={() => {
    isActive = !isActive;
  }}
>
  <div class="text font-18"><span>Твоя скидка истекает</span></div>

  <div class="timer font-24">
    <span>
      {`${h >= 10 ? h : '0'.concat(h)}:${m >= 10 ? m : '0'.concat(m)}:${
        s >= 10 ? s : '0'.concat(s)
      }`}
    </span>
  </div>
</div>

<style lang="scss">
  @import '../variables.scss';

  .timer {
    width: 13rem;
    color: $accent-color;
    font-weight: 800;
    text-align: center;
    transform: rotate(90deg);
    margin-left: -10.5rem;
    margin-top: 6.5rem;
  }

  .text {
    display: none;
    color: $accent-color;
    font-weight: 500;
    text-align: center;
    width: 85%;
    margin-top: 2.4rem;
    transition: display 0.3s ease-in-out 0.3s;
  }

  .container {
    position: absolute;
    height: 20rem;
    width: 16rem;
    top: 110px;
    right: 16px;
    background-image: url('/images/puzzle.png');
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(11.5rem);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 4.8rem;
  }

  .active {
    transform: translate(0px);
    .timer {
      transform: rotate(0deg);
      margin-left: 0rem;
      margin-top: 1.2rem;
    }
    .text {
      display: block;
    }
  }

  @media screen and (max-width: 600px) {
    .container {
      right: 2px;
      top: 7rem;
      height: 9rem;
      width: 9rem;
      padding-left: 2.6rem;
      transform: translate(6rem);
    }

    .active {
      transform: translate(0px);
      .timer {
        margin-top: 0.2rem;
      }
    }

    .timer {
      width: 7rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-top: 3.6rem;
      margin-left: -6rem;
    }

    .text {
      margin-top: 0.5rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }

  @media screen and (max-width: 390px) {
    .container {
      top: 7rem;
    }
  }
</style>
