<script>
  import { SwiperSlide } from 'swiper/svelte';

  import Modal from '../common/Modal.svelte';
  import Button from '../common/Button.svelte';
  import YoutubePlayer from '../common/YoutubePlayer.svelte';
  import SwiperCarousel from '../common/SwiperCarousel.svelte';
  import LongArrow from '../icons/LongArrow.svelte';
  import Image from '../common/Image.svelte';
  import { BUTTON_TYPES, COLORS, MODAL_IDS, MODAL_TYPES } from '../../constants/inner';
  import { PACKAGE_SIZES, PUZZLES } from '../../constants/content';
  import { closeModal } from '../../store/modals';
  import { getCurrency, openWindowWithKey } from '../../utils';
  import region from '../../store/region';

  export let visible;
  export let windowWidth;
  export let puzzleKey = PUZZLES.BISON.key;

  $: puzzleData = PUZZLES[puzzleKey];
</script>

{#if visible}
  <Modal
    close={() => closeModal(MODAL_IDS.PUZZLE_INFO)}
    closeColor={COLORS.ACCENT}
    type={MODAL_TYPES.FULLSCREEN}
  >
    <div class="container">
      <div class="left">
        <div class:bison={puzzleKey === PUZZLES.BISON.key} class="carousel">
          <SwiperCarousel slidesPerView={1}>
            <span slot="left-control"><LongArrow color={COLORS.LIGHT} /></span>

            {#each puzzleData[windowWidth > 600 ? 'images' : 'imagesSmall'].slice(2) as { src, alt }, i (alt)}
              <SwiperSlide>
                <div
                  class:shorter={i === 0}
                  class:more-shorter={i === 0 && puzzleKey === PUZZLES.OWL.key}
                  class="image"
                >
                  <Image {alt} {src} />
                </div>
              </SwiperSlide>
            {/each}

            <span slot="right-control"><LongArrow color={COLORS.LIGHT} /></span>
          </SwiperCarousel>
        </div>

        <h3 class="discount-price">{puzzleData.price[$region]} {getCurrency($region)}</h3>
        <h2 class="price">{puzzleData.discountPrice[$region]} {getCurrency($region)}</h2>
        <Button
          on:click={() => {
            localStorage.setItem('active-puzzle', puzzleKey);
            closeModal(MODAL_IDS.PUZZLE_INFO);
            setTimeout(() => {
              openWindowWithKey('purchase');
            }, 300);
          }}
          type={BUTTON_TYPES.LIGHT}
        >
          Купить
        </Button>
      </div>
      <div class="right">
        <h3 class="name">{puzzleData.name}</h3>

        <p class="subtitle">Размер пазла</p>
        <p class="medium">высота {puzzleData.specs.size.y} мм</p>
        <p class="medium">ширина {puzzleData.specs.size.x} мм</p>
        <p class="medium">толщина {puzzleData.specs.size.z} мм</p>

        <p class="subtitle">Размер упаковки пазла</p>
        <p class="medium">длина {PACKAGE_SIZES.x} мм</p>
        <p class="medium">ширина {PACKAGE_SIZES.y} мм</p>
        <p class="medium">высота {PACKAGE_SIZES.z} мм</p>

        <p class="subtitle">Количество деталей</p>
        <p class="medium">{puzzleData.specs.detailsNumber} деталей</p>
        <p class="subtitle">Материалы пазла</p>
        <p class="medium">
          HDF 3.2 мм экологическая классность Е1 Березовая фанера 4мм экологическая классность Е1
        </p>
        {#if PUZZLES[puzzleKey].video}
          <div class="video">
            <YoutubePlayer {windowWidth} url={PUZZLES[puzzleKey].video} />
          </div>
        {/if}
      </div>
    </div>
  </Modal>
{/if}

<style lang="scss">
  @import '../../variables.scss';

  .container {
    height: 100%;
    width: 100%;
    display: flex;
  }

  p {
    @extend .font-18 !optional;
  }

  p,
  h3,
  h2 {
    color: $accent-color;
    text-align: start;
  }

  h3.name {
    font-weight: 500;
    margin-top: 3rem;
    color: $accent-color;
  }

  h3.discount-price {
    color: $disabled-color;
    text-decoration-line: line-through;
  }

  .price {
    margin-bottom: 40px;
  }

  .subtitle {
    margin-top: 3rem;
    margin-bottom: 2rem;
    max-width: 29rem;
  }

  .right {
    width: calc(50% - 16.4rem);
    padding: 5.2rem 8.2rem;
    background-color: $light-color;
    overflow-y: auto;
  }

  .left {
    background-color: $primary-color;
    width: 50%;
    padding: 89px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel,
  h3 {
    margin-bottom: 30px;
  }
  .carousel {
    width: 60%;
    height: auto;
  }

  .image {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .medium {
    font-weight: 500;
  }

  .video {
    margin-top: 5.2rem;
    margin-bottom: 3rem;
    width: 100%;
  }

  @media screen and (max-width: 850px) {
    .container {
      flex-direction: column;
    }

    // .carousel {
    //   width: 65%;
    // }
    .left,
    .right {
      width: calc(100% - 70px);
      height: 50%;
      padding: 20px 35px;
    }

    .left {
      height: 60%;
    }

    .image {
      margin-bottom: 20px;
    }

    h3 {
      margin-bottom: 20px;
    }

    .price {
      margin-bottom: 25px;
    }
  }

  @media screen and (max-width: 600px) {
    .right {
      padding: 20px;
      width: calc(100% - 40px);
    }

    .video {
      width: 100%;
      height: auto;
    }

    .carousel {
      width: 90%;
    }

    .image {
      margin-bottom: 5px;
    }

    .shorter {
      width: 90%;
      margin-left: 10px;
    }

    .bison {
      width: 100%;
    }

    h3 {
      margin-bottom: 5px;
    }

    .price {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 390px) {
    .carousel {
      margin-bottom: 1.5rem;
    }
    .left {
      padding-bottom: 1rem;
    }
    h2 {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    .shorter {
      width: 70%;
      margin-left: 30px;
    }

    .more-shorter {
      width: 60%;
      margin-left: 38px;
    }

    .bison {
      width: 90%;

      .shorter {
        width: 75%;
        margin-left: 20px;
      }

      .more-shorter {
        width: 65%;
        margin-left: 30px;
      }
    }

    h3 {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    h3.name {
      margin-top: 1.5rem;
    }
  }
</style>
