<script>
  import * as animateScroll from 'svelte-scrollto';

  import { BUTTON_TYPES, REGIONS } from '../../constants/inner';
  import region from '../../store/region';
  import Button from '../common/Button.svelte';
</script>

<section id="howTo">
  <h1>Как сделать заказ</h1>
  <div class="container">
    <div class="info left first">
      <span>01</span>
      <img alt="how-to-order-1" src="/images/how-to-order-1.svg" />
      <p>Оставляйте заявку на сайте заполнив форму ниже.</p>
    </div>
    <div class="info right">
      <span>02</span>
      <p>Наш менеджер перезвонит по оставленному номеру и уточнит детали заказа.</p>
      <img alt="how-to-order-2" src="/images/how-to-order-2.svg" />
    </div>
    <div class="info left">
      <span>03</span>
      <img alt="how-to-order-2" src="/images/how-to-order-3.svg" />
      <p>
        {$region === REGIONS.BY
          ? 'Доставляем вашу посылку в любую точку Беларуси в течении 1-3 дней до дверей или в почтовое отделение на выбор.'
          : 'Доставляем вашу посылку по Москве, Санк-Питербургу в течении 2-4 дней до дверей.'}
      </p>
    </div>
    <div class="info right">
      <span>04</span>
      <p>
        {$region === REGIONS.BY
          ? 'Нет рисков. Нет предоплат. Оплачивайте заказ при получении в руки. Не понравился пазл? Мы вернем деньги.'
          : 'Оплата производится на Яндекс деньги. Не понравился пазл ? Мы вернем вам деньги.'}
      </p>
      <img alt="how-to-order-2" src="/images/how-to-order-4.svg" />
    </div>
  </div>
  <div class="button">
    <Button
      on:click={() => animateScroll.scrollTo({ element: '#catalog' })}
      type={BUTTON_TYPES.ACCENT}
    >
      Заказать
    </Button>
  </div>
</section>

<style lang="scss">
  @import '../../variables.scss';

  section {
    height: 100%;
    padding: 20px 25.4rem;
  }

  h1 {
    padding: 100px 0;
    color: $accent-color;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .info {
    position: relative;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
  }

  span {
    @extend .font-250 !optional;

    position: absolute;
    color: rgba(209, 175, 143, 0.1);
    font-style: normal;
    font-weight: 800;
    cursor: default;
    top: -13rem;
  }

  p {
    color: $accent-color;
    width: 50rem;
  }

  .left {
    align-self: flex-start;

    span {
      left: 160px;
    }

    p {
      text-align: start;
      margin-left: 16.4rem;
    }
  }

  .right {
    align-self: flex-end;
    span {
      right: 110px;
    }

    p {
      text-align: end;
      margin-right: 8.5rem;
    }
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 900px) {
    section {
      padding: 20px 4.2rem;
    }
    p {
      max-width: 30rem;
    }
    .info {
      margin-bottom: 30px;
      align-self: center;

      img {
        margin-bottom: 20px;
      }

      p {
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .info {
      span {
        top: 120px;
      }
    }

    .left {
      flex-direction: column;

      span {
        left: 80px;
      }
    }

    .first.left {
      span {
        left: 85px;
      }
    }
    .right {
      flex-direction: column-reverse;

      span {
        right: 80px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .container {
      margin-bottom: 2rem;
    }
    h1 {
      padding: 3rem 0;
    }
  }
</style>
