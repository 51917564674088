<script>
  import { Swiper } from 'swiper/svelte';
  import throttle from 'lodash.throttle';
  import Loader from './Loader.svelte';

  export let onChange = () => {};
  export let slidesPerView = 1;
  export let initialSlide = 0;
  export let swiper = null;
  let rendered = false;

  function initSwiper({ detail: [newSwiper] }) {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      rendered = true;
    }, 500);
    swiper = newSwiper;
  }

  function onPrevClick() {
    swiper.slidePrev();
  }
  function onNextClick() {
    swiper.slideNext();
  }
</script>

<div class="swiper-container">
  <span on:click={throttle(onPrevClick, 700)} class="swiper-control left-control">
    <slot name="left-control" />
  </span>

  <Swiper
    on:swiper={initSwiper}
    on:slideChange={(event) => {
      rendered && onChange(event);
    }}
    loop={true}
    spaceBetween={50}
    {slidesPerView}
    {initialSlide}
  >
    <slot />
  </Swiper>

  <span on:click={throttle(onNextClick, 700)} class="swiper-control">
    <slot name="right-control" />
  </span>

  {#if !rendered}
    <div class="loader-container">
      <div class="loader-wrapper">
        <Loader />
      </div>
    </div>
  {/if}
</div>

<style global lang="scss">
  @import 'swiper/swiper.scss';
  @import '../../variables.scss';

  :global(.swiper-wrapper) {
    align-items: center;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .loader-container {
    position: absolute;
    background-color: $light-color;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader-wrapper {
    width: 50px;
    height: 50px;

    :global(.spinner) {
      border-top-color: $primary-color;
    }
  }

  .swiper-control.left-control {
    margin-right: 30px;
  }

  .swiper-control:not(.left-control) {
    margin-left: 30px;
  }

  .left-control :global(svg) {
    transform: rotate(180deg);
  }

  .swiper-control :global(svg) {
    width: 7.3rem;
  }

  @media screen and (max-width: 600px) {
    .swiper-control :global(svg) {
      width: 2.4rem;
    }

    .swiper-control.left-control {
      margin-right: 20px;
    }

    .swiper-control:not(.left-control) {
      margin-left: 20px;
    }
  }
</style>
