<script>
  export let error = false;
</script>

<div class="container">
  <div class:error class="icon">{error ? '!' : '?'}</div>
  <div class="tooltip">
    <slot />
  </div>
</div>

<style lang="scss">
  @import '../../variables.scss';

  .container {
    position: relative;
    margin-left: 5px;
  }

  .tooltip {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
    position: absolute;
    bottom: 25px;
    right: 20px;
    width: 300px;
    background-color: $light-color;
    transition: transform cubic-bezier(0.445, 0.05, 0.55, 0.95) 300ms 70ms,
      opacity cubic-bezier(0.445, 0.05, 0.55, 0.95) 100ms 100ms;
    transform: translateY(450px);
    opacity: 0;
    z-index: 3;
  }
  .icon.error {
    background-color: $error-color;
  }

  .icon:hover ~ .tooltip,
  .icon:active ~ .tooltip {
    opacity: 1;
    transform: translateY(0px);
  }

  .icon {
    color: $light-color;
    background-color: $primary-color;
    text-align: center;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 1.5rem;
    line-height: 2.1rem;
    border-radius: 50%;
    cursor: default;
  }

  @media screen and (max-width: 600px) {
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.9rem;
      line-height: 1.4rem;
    }

    .tooltip {
      right: -105px;
      width: 260px;
    }
  }
</style>
