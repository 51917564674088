<script>
  import { fade, slide } from 'svelte/transition';

  import { BUTTON_TYPES } from '../../constants/inner';
  import Button from '../common/Button.svelte';
  import Image from '../common/Image.svelte';
  import Arrow from '../icons/Arrow.svelte';

  let isExpanded = false;

  function onMoreClick() {
    isExpanded = true;
  }
</script>

<style lang="scss">
  @import '../../variables.scss';

  .image {
    display: flex;
    width: 65vw;
    max-width: 630px;
  }

  section {
    height: 100%;
    padding: 20px 10%;
    margin-bottom: 70px;
  }
  h1 {
    padding: 100px 0;
    color: $accent-color;
  }
  p {
    max-width: 700px;
    color: $accent-color;
    font-weight: 500;
  }

  .container {
    width: 100%;
    display: flex;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .left {
    padding-left: 5%;
    padding-bottom: 75px;

    p {
      text-align: start;
    }
  }

  .right {
    padding-right: 5%;

    p {
      text-align: end;
    }
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    bottom: -20px;
  }
  :global(.arrow) {
    height: 22px;
    width: 22px;
    fill: $accent-color;
    color: $accent-color;
  }

  @media screen and (max-width: 950px) {
    .container {
      flex-direction: column;
      align-items: center;
    }

    .second {
      flex-direction: column-reverse;
    }

    .button {
      justify-content: center;
    }

    .image {
      width: 80vw;
      margin-bottom: 2rem;
    }

    .left,
    .right {
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 45px;
      p {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 600px) {
    section {
      margin-bottom: 0px;
    }

    h1 {
      padding: 10px 0 40px 0;
    }
  }

  @media screen and (max-width: 390px) {
  }
</style>

<section id="history">
  <h1>История пазла “Красная книга”</h1>

  <div class="container">
    <div class="image">
      <Image alt="history-1" src="/images/history/1.png" />
    </div>
    <div class="content left">
      <div>
        <p>
          Первым нашим проектом, стало создание серии пазлов "Красная книга", который состоит из 12
          пазлов в форме различных редких животных красной книги. Целью проекта стало обращение
          внимания на проблему исчезновения прекрасных животных нашей планеты. Первым зверем стал
          Зубр и это не спроста, ведь помимо того, что ЗУБР является символом Беларуси, изображение
          этого могучего велика является всемирным символом движения за сохранение редких и
          исчезающих видов животных.
        </p>
        <p>
          Детали пазлов имеют форму различных животных и растений богатой флоры и фауны нашей
          планеты. Создавая пазл мы используем высокоэкономичные материалы.
        </p>
      </div>

      {#if !isExpanded}
        <div transition:fade class="button">
          <Button on:click={onMoreClick} type={BUTTON_TYPES.TRANSPARENT}>
            Больше
            <Arrow className="arrow" />
          </Button>
        </div>
      {/if}
    </div>
  </div>
  {#if isExpanded}
    <div transition:slide class="container second">
      <div class="content right">
        <div>
          <p>Подарите себе и своим близким яркие и тёплые эмоции за сбором наших пазлов.</p>
          <p>
            Собирая пазл вы погружаетесь в атмосферу загадочного и сказочного рассказа о уязвимых
            жителях красной книги, а также о хрупких и редких растениях.
          </p>
          <p>
            Ведь это не просто головоломка, а увлекательное путешествие по страницам красной книги.
          </p>
        </div>
      </div>
      <div class="image">
        <Image alt="history-2" src="/images/history/2.png" />
      </div>
    </div>
  {/if}
</section>
