<script>
  export let checked = false;
  export let label;
</script>

<label class="switch">
  <input type="checkbox" bind:checked />
  <span class="slider" />
  <span class="label">{label}</span>
</label>

<style lang="scss">
  @import '../../variables.scss';

  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .label {
    @extend .font-12 !optional;

    cursor: pointer;
    position: absolute;
    color: $light-color;
    bottom: 5px;
    right: 5px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $accent-color;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider + .label {
    left: 5px;
  }

  input:checked + .slider {
    background-color: $accent-color;
  }

  input:checked + .slider {
    box-shadow: 0 0 1px $accent-color;
  }

  input:checked + .slider:before {
    transform: translateX(30px);
  }

  @media screen and (max-width: 600px) {
    .label {
      font-size: 9px;
      line-height: 13px;
      font-weight: 600;
      bottom: 1px;
    }
    .switch {
      width: 43px;
      height: 16px;
    }

    .slider:before {
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
    }

    input:checked + .slider:before {
      transform: translateX(20px);
    }
  }
</style>
