<script>
  import throttle from 'lodash.throttle';
  import { SwiperSlide } from 'swiper/svelte';

  import { TESTIMONIALS_IMAGES } from '../../constants/content';
  import { COLORS, PAGER_TYPES } from '../../constants/inner';
  import Image from '../common/Image.svelte';
  import Pager from '../common/Pager.svelte';
  import SwiperCarousel from '../common/SwiperCarousel.svelte';
  import LongArrow from '../icons/LongArrow.svelte';

  let circledArray = [...TESTIMONIALS_IMAGES];
  let active = 1;
  let activeIndex = 3;

  export let animationStopper = false;
  export let windowWidth;

  function handleClick(index, number) {
    const counts = index === 1 || index === 5 ? 2 : 1;
    active = number;
    activeIndex = index;

    if (index !== 3) {
      index > 2
        ? circledArray.push(circledArray.shift())
        : circledArray.unshift(circledArray.pop());
      circledArray = circledArray;

      counts === 2 &&
        setTimeout(() => {
          index > 2
            ? circledArray.push(circledArray.shift())
            : circledArray.unshift(circledArray.pop());
          circledArray = circledArray;
        }, 200);
    }
  }

  function onChange(event) {
    const activeSlide = event.detail[0][0].activeIndex - 1;

    if (activeSlide === circledArray.length || activeSlide === -1) {
      activeIndex = 0;
    } else {
      activeIndex = activeSlide;
    }

    active = circledArray[activeIndex].number;
  }

  const throttledHandler = throttle(handleClick, 300);
</script>

<section id="testimonials">
  <h1>Что люди пишут о нас</h1>

  <div class="container">
    <div class="carousel">
      {#if windowWidth > 950}
        <div class:animation-stopper={animationStopper} class="carousel-content">
          {#each circledArray.slice(0, 7) as { src, alt, number }, i (alt)}
            <div
              class={`dynamic-image number-${i + 1}`}
              on:click={() => throttledHandler(i, number)}
            >
              <Image {alt} {src} />
            </div>
          {/each}
        </div>
      {:else}
        <div class="carousel-content">
          <SwiperCarousel {onChange} slidesPerView={1}>
            <span slot="left-control"><LongArrow color={COLORS.ACCENT} /></span>

            {#each circledArray as { src, alt } (alt)}
              <SwiperSlide>
                <div class="static-image">
                  <Image {alt} {src} />
                </div>
              </SwiperSlide>
            {/each}

            <span slot="right-control"><LongArrow color={COLORS.ACCENT} /></span>
          </SwiperCarousel>
        </div>
      {/if}
      <Pager {active} total={TESTIMONIALS_IMAGES.length} type={PAGER_TYPES.HORIZONTAL} />
    </div>
  </div>
</section>

<style lang="scss">
  @import '../../variables.scss';

  section {
    height: 100%;
    padding: 20px 8%;
  }

  h1 {
    padding: 100px 0;
    color: $accent-color;
  }

  .container {
    display: flex;
    align-items: center;
  }
  .carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .carousel-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    height: 55vh;
    position: relative;

    .animation-stopper * {
      transition: none;
    }
  }

  .dynamic-image {
    transition: transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95), width 20ms ease-in 280ms;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    width: 20%;
  }

  .number-1 {
    width: 0px;

    transform: translateX(-40vw) perspective(800px) rotateY(45deg) scale(0.6);
  }

  .number-2 {
    transform: translateX(-4vw) perspective(800px) rotateY(30deg) scale(0.6);
  }
  .number-3 {
    transform: translateX(13vw) perspective(800px) rotateY(15deg) scale(0.8);
  }
  .number-4 {
    transform: translateX(33vw) perspective(800px) rotateY(0deg) scale(1);
  }
  .number-5 {
    transform: translateX(53vw) perspective(800px) rotateY(-15deg) scale(0.8);
  }
  .number-6 {
    transform: translateX(70vw) perspective(800px) rotateY(-30deg) scale(0.6);
  }

  .number-7 {
    width: 0px;

    transform: translateX(2200px) perspective(800px) rotateY(-45deg) scale(0.6);
  }

  .static-image {
    width: 380px;
  }

  @media screen and (max-width: 1390px) {
    .dynamic-image {
      width: 30%;
    }
    .number-1 {
      transform: translateX(-40vw) perspective(800px) rotateY(45deg) scale(0.6);
    }
    .number-2 {
      transform: translateX(-40vw) perspective(800px) rotateY(30deg) scale(0.6);
    }
    .number-3 {
      transform: translateX(-7vw) perspective(800px) rotateY(15deg) scale(0.8);
    }
    .number-4 {
      transform: translateX(28vw) perspective(800px) rotateY(0deg) scale(1);
    }
    .number-5 {
      transform: translateX(62vw) perspective(800px) rotateY(-15deg) scale(0.8);
    }
    .number-6 {
      transform: translateX(270vw) perspective(800px) rotateY(-30deg) scale(0.6);
    }
    .number-7 {
      transform: translateX(2200px) perspective(800px) rotateY(-45deg) scale(0.6);
    }
  }

  @media screen and (max-width: 1090px) {
    .dynamic-image {
      width: 35%;
    }
  }

  @media screen and (max-width: 950px) {
    .carousel-content {
      padding-top: 0px;
      height: 700px;
    }
  }

  @media screen and (max-width: 600px) {
    .carousel-content {
      height: 37rem;
    }

    .static-image {
      width: 100%;
      height: auto;
    }

    h1 {
      padding: 10px 0 40px 0;
    }
  }
</style>
