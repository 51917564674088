<script>
  import cn from 'classnames';

  import Loader from './Loader.svelte';
  import { BUTTON_TYPES } from '../../constants/inner';

  export let ref = 'default';
  export let type = BUTTON_TYPES.ACCENT;
  export let disabled = false;
  export let loading = false;
</script>

<style lang="scss">
  @import '../../variables.scss';

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    padding: 0;
    font-weight: 500;
    border-width: 1px;
    font-family: Acrom;
    border-style: solid;
    cursor: pointer;
    border-color: transparent;
  }

  button:disabled {
    color: $light-color;
    background-color: $disabled-color;
    cursor: default;
  }

  .accent,
  .accent-extended,
  .light,
  .transparent {
    @extend .font-24 !optional;

    font-weight: 500;
    letter-spacing: 0.04rem;
    border-radius: 40px;
    padding: 15px 35px;
  }

  .accent,
  .accent-extended,
  .light:hover:not(:disabled) {
    color: $light-color;
    background-color: $accent-color;
  }

  .accent-extended {
    padding: 25px 60px;
  }

  .transparent {
    font-style: normal;
    justify-content: start;
    color: $accent-color;
    background-color: $light-color;
    border-color: $accent-color;
  }

  .accent-extended:hover:not(:disabled),
  .accent:hover:not(:disabled),
  .light {
    color: $accent-color;
    background-color: $light-color;
    border-color: $accent-color;
  }

  .secondary {
    @extend .font-16 !optional;

    color: $light-color;
    background-color: $primary-color;
    border-radius: 100px;
    padding: 6px 43px;

    :global(svg) {
      fill: $light-color;
    }
  }

  .secondary:hover {
    color: $primary-color;
    background-color: $light-color;
    border-color: $primary-color;

    :global(svg) {
      fill: $primary-color;
    }
  }

  .circle {
    @extend .font-24 !optional;

    border-color: $accent-color;
    border-radius: 50%;
    font-weight: 500;
  }

  .circle:hover {
    background-color: $primary-color;
    border-color: transparent;
    color: $light-color;
  }

  .circle:disabled {
    background-color: $light-color;
    border-color: $disabled-color;
    color: $disabled-color;
  }

  .transparent:hover {
    font-weight: 600;

    :global(svg) {
      height: 26px;
      width: 26px;
    }
  }

  .icon {
    background-color: inherit;
  }

  div {
    margin-left: 20px;
    height: 3rem;
    width: 3rem;
  }

  @media screen and (max-width: 600px) {
    .accent,
    .accent-extended,
    .light,
    .transparent {
      padding: 7px 27px;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    .secondary {
      padding: 6px 18px;

      :global(svg) {
        width: 10px;
        height: 10px;
      }
    }
  }
</style>

<button
  {ref}
  on:click
  disabled={disabled || loading}
  class={cn({ [type.toLowerCase()]: true, loading })}>
  <slot />
  {#if loading}
    <div>
      <Loader />
    </div>
  {/if}
</button>
