<script>
  import * as animateScroll from 'svelte-scrollto';

  import { STAND } from '../../constants/content';
  import { BUTTON_TYPES } from '../../constants/inner';
  import region from '../../store/region';
  import { getCurrency } from '../../utils';
  import Button from '../common/Button.svelte';
  import YoutubePlayer from '../common/YoutubePlayer.svelte';

  export let windowWidth;

  function onBuyClick() {
    animateScroll.scrollTo({ element: '#catalog' });
  }
</script>

<section id="stand">
  <h1>Подставка к пазлу</h1>

  <div class="container">
    <div class="video">
      <YoutubePlayer {windowWidth} url="iEBRDczVEn8" />
    </div>
    <div class="content">
      <h3>Размер упаковки подставки</h3>
      <p class="measure">высота 314 мм длина 214 мм ширина 50 мм</p>
      <p class="info">
        У многих любителей пазлов после пары часов, проведенных в увлекательном приключении сбора,
        появляется мысль: «Так не хочется разбирать эту красоту!»
      </p>
      <p class="info">
        Настольная подставка, выполненная из органического стекла делает пазл не просто интересной
        головоломкой, а прекрасным украшением интерьера и вашего рабочего стола.
      </p>
    </div>
  </div>

  <h3>{STAND.price[$region]} {getCurrency($region)}</h3>
  <div class="button">
    <Button on:click={onBuyClick} type={BUTTON_TYPES.ACCENT}>Заказать</Button>
  </div>
</section>

<style lang="scss">
  @import '../../variables.scss';

  section {
    height: 100%;
    width: calc(100% - 26vw + 15px);
    padding: 20px 13vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    padding: 100px 0;
    color: $accent-color;
  }

  p,
  h3 {
    color: $accent-color;
    text-align: start;
  }

  h3,
  .measure {
    margin-bottom: 30px;
  }
  p:nth-child(3),
  p:last-child {
    margin-bottom: 10px;
  }
  p {
    font-weight: 500;
  }

  .container {
    width: 100%;
    display: flex;
    margin-bottom: 7rem;
    align-items: center;
  }
  .video,
  .content {
    width: 50%;
    min-height: 420px;
  }

  .content {
    padding-left: 6.5rem;
    display: flex;
    flex-direction: column;
  }
  .measure {
    max-width: 23.7rem;
  }
  .info {
    max-width: 59rem;
  }
  .button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 950px) {
    section {
      width: calc(100% - 84px + 15px);
      padding: 20px 42px;
    }
    p,
    h3 {
      text-align: center;
    }
    .container {
      flex-direction: column;
      align-items: center;
    }
    .content {
      width: 100%;
    }
    .content {
      padding-left: 0;
      align-items: center;
    }
    .video {
      width: 80vw;
      height: 40vw;
      margin-bottom: 50px;
    }
    .info {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    section {
      width: calc(100% - 84px);
    }

    .container {
      margin-bottom: 3rem;
    }

    .video {
      width: 80vw;
      height: auto;
      min-height: 250px;
      margin-bottom: 30px;
    }

    .content {
      min-height: 300px;
    }

    h1 {
      padding: 20px 0 40px 0;
    }
  }
</style>
