<script>
  import { slide } from 'svelte/transition';

  import { BUTTON_TYPES } from '../../constants/inner';
  import Search from '../icons/Search.svelte';
  import Button from './Button.svelte';
  import Image from './Image.svelte';

  export let key;
  export let name;
  export let image;
  export let hoverImage;
  export let imageAlt;
  export let hoverImageAlt;
  export let onInfoClick;
  export let onBuyClick;
  export let price;
  export let discountPrice;
  let hovered;

  function onHover() {
    hovered = true;
  }
  function onLeave() {
    hovered = false;
  }
</script>

<div transition:slide class="container">
  <div on:mouseenter={onHover} on:mouseleave={onLeave} class="puzzle-lot-image">
    <Image alt={hovered ? hoverImageAlt : imageAlt} src={hovered ? hoverImage : image} />
  </div>

  <div class="title">
    <p>{name}</p>
  </div>
  <div class="button">
    <Button on:click={() => onInfoClick(key)} type={BUTTON_TYPES.SECONDARY}>
      <span>Подробнее</span>
      <Search />
    </Button>
  </div>

  <h3>{price}</h3>
  <h2>{discountPrice}</h2>
  <div class="button">
    <Button on:click={() => onBuyClick(key)} type={BUTTON_TYPES.ACCENT}>Заказать</Button>
  </div>
</div>

<style lang="scss">
  @import '../../variables.scss';

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 270px;
    margin-right: 5rem;
  }

  span {
    margin-right: 10px;
  }

  .puzzle-lot-image {
    height: 350px;
    width: 80%;
    display: flex;
    align-items: center;

    :global(img) {
      max-width: 100%;
      max-height: 350px;
      height: auto;
    }
  }

  .puzzle-lot-image,
  .title,
  .button,
  h2 {
    margin-bottom: 3rem;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.2rem;
  }

  .button {
    display: flex;
    justify-content: center;
  }
  p {
    font-weight: 500;
    color: $accent-color;
  }
  h2 {
    @extend .font-36 !optional;

    color: $accent-color;
  }
  h3 {
    @extend .font-26 !optional;

    color: $disabled-color;
    text-decoration-line: line-through;
  }

  @media screen and (max-width: 800px) {
    .container {
      width: 180px;
      margin-right: 0rem;
    }

    .puzzle-lot-image {
      :global(img) {
        max-width: 110%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .puzzle-lot-image {
      max-height: 160px;
    }
    .container {
      width: 110px;
      margin-right: 0rem;
    }

    .puzzle-lot-image,
    .title,
    .button,
    h2 {
      margin-bottom: 1rem;
    }
  }
</style>
