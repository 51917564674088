<script>
  import * as animateScroll from 'svelte-scrollto';

  import Button from './common/Button.svelte';
  import { BUTTON_TYPES, MODAL_IDS } from '../constants/inner';
  import { LINKS, SECTIONS } from '../constants/content';
  import { openLink } from '../utils';
  import { openModal } from '../store/modals';
  import Image from './common/Image.svelte';

  export let windowWidth;
</script>

<footer id="footer">
  <div class="right-background-puzzle">
    <Image alt="puzzle" src="/images/puzzle-2.png" />
  </div>
  <div class="left-background-puzzle">
    <Image alt="puzzle" src="/images/puzzle-2.png" />
  </div>

  {#if windowWidth > 950}
    <div>
      <h2>Меню</h2>
      <div class="menu">
        {#each SECTIONS as { name, id } (id)}
          <a href={`#${id}`} on:click={() => animateScroll.scrollTo({ element: `#${id}` })}>
            {name}
          </a>
        {/each}
      </div>
    </div>
  {/if}

  <div>
    <h2>Контакты</h2>
    <div class="contacts">
      <p>Беларусь, г. Витебск ул. Максима Горького, 59 Офис 58</p>
      <p>+375 29 210 2999</p>
      <p>touchwoodby@mail.ru</p>
    </div>
  </div>
  <div>
    {#if windowWidth > 950}
      <h2>Социальные</h2>
    {/if}

    <div class="links">
      <Button ref="footer" on:click={() => openLink(LINKS.INST)} type={BUTTON_TYPES.ICON}>
        <img class="icon" alt="instagram" src="/images/inst-big.svg" />
      </Button>
      <Button ref="footer" on:click={() => openLink(LINKS.VK)} type={BUTTON_TYPES.ICON}>
        <img class="icon" alt="vk" src="/images/vk-big.svg" />
      </Button>
      <Button ref="footer" on:click={() => openLink(LINKS.EMAIL)} type={BUTTON_TYPES.ICON}>
        <img class="icon" alt="email" src="/images/email-big.svg" />
      </Button>
    </div>
  </div>

  <span on:click={() => openModal(MODAL_IDS.PRIVACY)} class="terms font-18"
    >Политика конфиденциальности</span
  >
  <span class="copyright font-24">© Copyright 2021</span>
  <span class="ip font-18">ИП Карелин Н.С. УНП: 391822906</span>
</footer>

<style lang="scss">
  @import '../variables.scss';

  footer {
    height: 400px;
    width: calc(100% - 30rem);
    position: relative;
    padding: 150px 15rem;
    background-color: $primary-color;
    display: flex;
    justify-content: space-between;
  }
  h2 {
    color: $light-color;
    font-weight: normal;
    margin-bottom: 4rem;
    text-align: start;
  }
  a {
    color: $light-color;
    margin-bottom: 4rem;
    cursor: pointer;
  }
  p {
    color: $light-color;
    font-weight: normal;
    text-align: start;
    margin-bottom: 4rem;
  }

  .right-background-puzzle,
  .left-background-puzzle {
    position: absolute;
    height: 16.5rem;
    width: 25.7rem;
  }
  .left-background-puzzle {
    left: 5rem;
    top: -8rem;
    transform: rotate(45deg);
  }
  .right-background-puzzle {
    right: 5rem;
    top: -8.5rem;
    transform: rotate(25deg);
  }

  .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    max-height: 35rem;
    margin-right: 5rem;
    width: 57rem;
    align-items: flex-start;
  }

  .contacts {
    max-width: 31.5rem;
  }
  .links {
    display: flex;
  }
  .icon {
    height: 5rem;
    width: 5rem;
  }

  .terms,
  .copyright,
  .ip {
    position: absolute;
    bottom: 10rem;
  }

  .terms,
  .ip {
    color: rgba(255, 246, 238, 0.8);
  }

  .terms {
    left: 15rem;
    cursor: pointer;
  }

  .ip {
    align-self: center;
    bottom: 5rem;
    right: 15rem;
    text-align: center;
  }

  .copyright {
    color: $light-color;
    right: 15rem;
  }

  :global([ref='footer']) {
    margin-right: 6rem;
  }

  @media screen and (max-width: 1090px) {
    footer {
      padding: 150px 12rem;
      width: calc(100% - 24rem);
      height: 200px;
    }

    .terms {
      left: 12rem;
    }

    .copyright {
      right: 12rem;
    }
  }
  @media screen and (max-width: 600px) {
    footer {
      width: calc(100% - 8rem);
      padding: 4rem;
      flex-direction: column;
      height: auto;
      align-items: center;
    }

    h2,
    p,
    .links {
      text-align: center;
      margin-bottom: 2rem;
    }

    :global([ref='footer']) {
      margin-right: 4.3rem;

      &:last-child {
        margin-right: 0rem;
      }
    }
    .right-background-puzzle,
    .left-background-puzzle {
      height: 5rem;
      width: 7.7rem;
    }

    .left-background-puzzle {
      left: 3rem;
      top: -2.5rem;
    }
    .right-background-puzzle {
      right: 3rem;
      top: -2.5rem;
    }

    .icon {
      height: 2.5rem;
      width: 2.5rem;
    }
    .terms,
    .copyright,
    .ip {
      position: relative;
      left: 0px;
      right: 0px;
      top: 0px;
    }
    .terms {
      margin-bottom: 2rem;
    }

    .ip {
      margin-top: 2rem;
    }
  }
</style>
