<script>
  import Image from './Image.svelte';

  export let image;
  export let imageAlt;
  export let price;
  export let discountPrice;
</script>

<div class="container">
  <div class="puzzle-buying-lot-image">
    <Image alt={imageAlt} src={image} />
  </div>
  <span class="price">{price}</span>
  <span class="discount-price">{discountPrice}</span>
</div>

<style lang="scss">
  @import '../../variables.scss';

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .puzzle-buying-lot-image {
    width: 70%;
    :global(img) {
      margin-bottom: 20px;
    }
  }

  .price {
    @extend .font-26 !optional;
    color: $disabled-color;
    text-decoration-line: line-through;
  }
  .discount-price {
    @extend .font-36 !optional;

    font-weight: 700;
    color: $accent-color;
    margin-bottom: 10px;
  }

  :global([ref='count-button']) {
    @extend .font-24 !optional;

    color: $disabled-color;
    background-color: $light-color;
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 850px) {
    .puzzle-buying-lot-image {
      width: 80%;
    }
  }
  @media screen and (max-width: 600px) {
    .puzzle-buying-lot-image {
      width: 70%;
    }
    :global([ref='count-button']) {
      width: 23px;
      height: 23px;
    }
  }
</style>
