<script>
  export let src;
  export let alt;
  export let className = '';

  $: filePath = src.slice(0, src.lastIndexOf('.'));
</script>

<style lang="scss">
  img {
    width: 100%;
    height: auto;
  }
</style>

<picture>
  <source type="image/webp" srcset={`${filePath}.webp`} />
  <img on:click class={className} {src} {alt} />
</picture>
