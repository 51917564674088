<script>
  export let color;
</script>

<svg width="73" height="50" viewBox="0 0 73 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <line
    x1="1.5"
    y1="25.5"
    x2="68.5"
    y2="25.5"
    stroke={color}
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <line
    x1="48.1213"
    y1="3"
    x2="70"
    y2="24.8787"
    stroke={color}
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <line
    x1="1.5"
    y1="-1.5"
    x2="32.4411"
    y2="-1.5"
    transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 46 47)"
    stroke={color}
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
