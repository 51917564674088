import qs from 'qs';

const CRM_URL = 'https://stickcrm.by/api/orders';
const EMAIL_URL = 'https://touchwood.by/mail.php';

const API_TOKEN = 'KLGiO2ifF05gI1EVkdbp1mCHsrmv7ihBYrjPAcLnUWxd13mGBD8YNTbJwBVc';

function post(url, body) {
  const data = qs.stringify(body, { arrayFormat: 'indices' });

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

const apiService = {
  sendOrderToCrm({ name, phone, products }) {
    return post(CRM_URL, {
      api_token: API_TOKEN,
      order: {
        name,
        phone,
        products,
      },
    });
  },
  sendOrderEmail({ name, phone, total, order, region }) {
    return post(EMAIL_URL, {
      name,
      phone,
      message: `Регион: ${region}. Сумма заказа: ${total}\n${order.map(
        ([key, { quantity, withStand }]) =>
          `${key}: ${quantity}, ${withStand ? 'с подставкой\n' : '\n'}`,
      )}`,
    });
  },
  async getGeolocation() {
    const country = await fetch('https://get.geojs.io/v1/ip/country');

    return country.text();
  },
};

export default apiService;
