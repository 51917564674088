<script>
  import { onDestroy } from 'svelte';
  import * as animateScroll from 'svelte-scrollto';

  import Button from '../common/Button.svelte';
  import Pager from '../common/Pager.svelte';
  import Timer from '../Timer.svelte';
  import Image from '../common/Image.svelte';
  import { BUTTON_TYPES, PAGER_TYPES } from '../../constants/inner';
  import { firstSectionSlides, PUZZLES } from '../../constants/content';
  import region from '../../store/region';
  import { getCurrency } from '../../utils';

  let activeScreen = 0;
  export let windowWidth;
  const interval = setInterval(() => {
    activeScreen = activeScreen === firstSectionSlides.length - 1 ? 0 : activeScreen + 1;
  }, 4000);

  onDestroy(() => clearInterval(interval));
</script>

<section id="intro">
  <div class="right-background-puzzle">
    <Image
      alt="puzzle"
      src={windowWidth > 600 ? '/images/puzzle-2.png' : '/images/puzzle-2_380.png'}
    />
  </div>
  <div class="left-background-puzzle">
    <Image
      alt="puzzle"
      src={windowWidth > 600 ? '/images/puzzle-2.png' : '/images/puzzle-2_380.png'}
    />
  </div>

  <div class="left-content">
    <div class="title">
      <h1>{firstSectionSlides[activeScreen].text}</h1>
      <span>{`0${activeScreen + 1}`}</span>
    </div>
    <div>
      <h3>{PUZZLES.BISON.price[$region]} {getCurrency($region)}</h3>
      <h2>{PUZZLES.BISON.discountPrice[$region]} {getCurrency($region)}</h2>
      <Button
        on:click={() => animateScroll.scrollTo({ element: '#catalog' })}
        type={BUTTON_TYPES.ACCENT}
      >
        Заказать
      </Button>
    </div>
  </div>
  <div class="right-content">
    <div class="leaves">
      <Image
        alt="leaves"
        src={windowWidth > 600 ? '/images/leaves.png' : '/images/leaves_380.png'}
      />
    </div>

    <div class="puzzle">
      <div class="puzzle-image">
        <Image alt="puzzle" src={firstSectionSlides[activeScreen].image} />
      </div>
      <div class="pager">
        {#if windowWidth > 600}
          <Pager
            type={PAGER_TYPES.VERTICAL}
            total={firstSectionSlides.length}
            active={activeScreen + 1}
          />
        {/if}
      </div>
    </div>
  </div>
  <Timer />
</section>

<style lang="scss">
  @import '../../variables.scss';

  h1,
  h2,
  h3 {
    text-align: start;
  }
  h1 {
    color: $light-color;
    margin: 6rem 0px;
    max-width: 70rem;
    font-weight: 700;
  }
  h2 {
    color: $accent-color;
    margin-bottom: 4rem;
  }
  h3 {
    margin-bottom: 0.8rem;
    color: $disabled-color;
    text-decoration-line: line-through;
  }

  span {
    @extend .font-250 !optional;

    color: rgba(255, 246, 238, 0.15);
    font-style: normal;
    font-weight: 800;
    position: absolute;
    top: -8rem;
    left: 0;
    cursor: default;
  }

  section {
    width: calc(100% - 30rem + 15px);
    height: calc(100vh - 35rem);
    padding: calc(140px + 8.6rem) 15rem 140px 15rem;
    background-color: $primary-color;
    display: flex;
    position: relative;
  }

  .left-content {
    height: 100%;
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .right-content {
    position: absolute;
    left: 51%;
    display: flex;
    align-items: flex-start;
  }

  .puzzle {
    position: absolute;
    left: 11.5vw;
    top: 4.5vw;
    display: flex;
    align-items: center;
  }

  .right-background-puzzle,
  .left-background-puzzle {
    position: absolute;
    height: 16.5rem;
    width: 25.7rem;
  }
  .left-background-puzzle {
    left: 5rem;
    bottom: -9.5rem;
    transform: rotate(40deg);
  }
  .right-background-puzzle {
    right: 5rem;
    bottom: -5.1rem;
    transform: rotate(-20deg);
  }

  .leaves,
  .puzzle-image {
    width: 23vw;
  }

  .pager {
    margin-left: 4rem;
    height: 29vw;
  }

  @media screen and (max-width: 900px) {
    section {
      padding: calc(30px + 8.6rem) 37px 30px 37px;
      width: calc(100% - 74px + 15px);
      height: 130rem;
    }

    span {
      left: 0rem;
      top: -12rem;
    }

    h1 {
      max-width: 61rem;
      margin: 0rem 0px;
    }

    .title {
      align-self: flex-start;
    }
    .left-content {
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .right-content {
      left: 20%;
      top: 20%;
    }

    .leaves,
    .puzzle-image {
      width: 45vw;
    }

    .pager {
      height: 45vw;
    }
  }

  @media screen and (max-width: 600px) {
    section {
      padding: calc(30px + 8.6rem) 30px 10px 30px;
      height: 80vh;
      width: calc(100% - 60px);
    }

    span {
      top: -5rem;
    }

    .left-content {
      height: 93%;
      h1 {
        max-width: 75%;
        text-align: center;
      }
      h2,
      h3 {
        text-align: center;
      }

      h3 {
        margin-bottom: 1rem;
      }

      h2 {
        margin-bottom: 2rem;
      }
    }

    .right-content {
      top: 25%;
      left: 5%;
    }

    .leaves {
      width: 45vw;
    }
    .puzzle-image {
      width: 55vw;
    }

    .puzzle {
      left: 17.5vw;
      top: 5vw;
    }

    .pager {
      height: 60vw;
    }

    .right-background-puzzle,
    .left-background-puzzle {
      height: 5rem;
      width: 7.7rem;
    }

    .left-background-puzzle {
      left: 5rem;
      bottom: -2.5rem;
    }
    .right-background-puzzle {
      right: 5rem;
      bottom: -1.5rem;
    }
  }

  @media screen and (max-width: 390px) {
    .right-content {
      top: 30%;
    }

    .puzzle {
      left: 21.5vw;
      top: 3vw;
    }

    .puzzle-image {
      width: 45vw;
    }
    span {
      top: -4rem;
    }
    section {
      padding: calc(20px + 8.6rem) 20px 20px 20px;
      width: calc(100% - 40px);
    }
    .left-content {
      h1 {
        max-width: 70%;
      }
    }
  }
</style>
