<script>
  export let url;
  export let windowWidth;
</script>

<style>
  .video {
    width: 100%;
  }
</style>

<iframe
  class="video"
  title="stand-video"
  type="text/html"
  width="750"
  height={windowWidth < 410 ? '250' : '420'}
  src={`https://www.youtube.com/embed/${url}?origin=https://touchwood.by`}
  frameborder="0"
  allowfullscreen="allowfullscreen"
  mozallowfullscreen="mozallowfullscreen"
  msallowfullscreen="msallowfullscreen"
  oallowfullscreen="oallowfullscreen"
  webkitallowfullscreen="webkitallowfullscreen" />
