<script>
  import * as animateScroll from 'svelte-scrollto';

  import { SECTIONS } from '../../constants/content';
  import { closeModal } from '../../store/modals';
  import { COLORS, MODAL_IDS, MODAL_TYPES } from '../../constants/inner';
  import Modal from '../common/Modal.svelte';
  import Image from '../common/Image.svelte';

  export let visible;
  export let windowWidth;
  function onLinkClick(id) {
    animateScroll.scrollTo({ element: `#${id}` });
    closeModal(MODAL_IDS.NAVIGATION);
  }
</script>

<style lang="scss">
  @import '../../variables.scss';

  .container {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
  }

  a {
    color: $light-color;
    margin-bottom: 30px;
    text-align: start;
  }

  .menu {
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 115px 155px;
    flex-grow: 1;
  }
  :global(.background-image) {
    background-image: url('');
    background-size: auto;
    height: 100%;
    width: 400px;
  }
  .background-light {
    height: 100%;
    width: 64px;
    background-color: $light-color;
  }
  .background-disabled {
    background-color: $disabled-color;
    height: 100%;
    width: 51px;
  }

  @media screen and (max-width: 600px) {
    .container {
      flex-direction: column;
    }
    .menu {
      padding: 2rem 1.5rem 5rem 1.5rem;
      align-items: center;
      overflow-y: auto;
    }

    :global(.background-image) {
      height: 38vw;
      width: 100%;
    }
    .background-light {
      height: 2.4rem;
      width: 100%;
    }
    .background-disabled {
      height: 1.8rem;
      background-color: #e1c6ad;
      width: 100%;
    }
    a {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
</style>

{#if visible}
  <Modal
    close={() => closeModal(MODAL_IDS.NAVIGATION)}
    closeColor={windowWidth > 600 ? COLORS.LIGHT : COLORS.ACCENT}
    type={MODAL_TYPES.FULLSCREEN}>
    <div class="container">
      <Image
        alt="navigation-background"
        className="background-image"
        src={windowWidth > 600 ? '/images/nav-background.png' : '/images/nav-background_380.png'} />
      <div class="background-light" />
      <div class="background-disabled" />
      <nav class="menu">
        {#each SECTIONS as { name, id } (id)}
          <a class="font-42" href={`#${id}`} on:click={() => onLinkClick(id)}> {name} </a>
        {/each}
      </nav>
    </div>
  </Modal>
{/if}
