import { REGIONS } from './inner';

export const firstSectionSlides = [
  { text: 'Уникальный пазл из лиственных пород дерева', image: '/images/intro/1.png' },
  { text: '150 деталей в виде животных красной книги', image: '/images/intro/2.png' },
];

function getImages(key, postfix = '') {
  return [
    { src: `/images/products/${key}/preview${postfix}.png`, alt: `${key}_preview` },
    { src: `/images/products/${key}/box${postfix}.png`, alt: `${key}_box` },
    { src: `/images/products/${key}/front${postfix}.png`, alt: `${key}_front` },
    { src: `/images/products/${key}/opened_box${postfix}.png`, alt: `${key}_box_opened` },
  ];
}

const PUZZLE_DISCOUNT_PRICE_RUB = 1890;
const PUZZLE_PRICE_RUB = 2290;
const PUZZLE_DISCOUNT_PRICE_BY = 64;
const PUZZLE_PRICE_BY = 79;
const STAND_PRICE_BY = 22;
const STAND_PRICE_RUB = 650;

export const PUZZLES = {
  BISON: {
    key: 'BISON',
    name: 'Могучий зубр',
    images: getImages('bison'),
    imagesSmall: getImages('bison', '_380'),
    video: 'Re5x93eB0PE',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 9879,
    specs: {
      detailsNumber: 146,
      size: {
        x: 205,
        y: 230,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  ALYX: {
    key: 'ALYX',
    name: 'Изящная рысь',
    images: getImages('alyx'),
    imagesSmall: getImages('alyx', '_380'),
    video: 'R-5InGKST3k',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 9880,
    specs: {
      detailsNumber: 134,
      size: {
        x: 205,
        y: 255,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  OWL: {
    key: 'OWL',
    name: 'Мудрый филин',
    images: getImages('owl'),
    imagesSmall: getImages('owl', '_380'),
    video: 'FyxpElToZes',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 9886,
    specs: {
      detailsNumber: 151,
      size: {
        x: 185,
        y: 290,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  BEAR: {
    key: 'BEAR',
    name: 'Михаил Потапыч',
    images: getImages('bear'),
    imagesSmall: getImages('bear', '_380'),
    video: 'WmXehesg3vI',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 10374,
    specs: {
      detailsNumber: 158,
      size: {
        x: 205,
        y: 280,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  BADGER: {
    key: 'BADGER',
    name: 'Таинственный барсук',
    images: getImages('badger'),
    imagesSmall: getImages('badger', '_380'),
    video: 'ZOYXdR6xHpo',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 10374,
    specs: {
      detailsNumber: 152,
      size: {
        x: 205,
        y: 255,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  WOLF: {
    key: 'WOLF',
    name: 'Пылающий волк',
    images: getImages('wolf'),
    imagesSmall: getImages('wolf', '_380'),
    video: 'G6KCpRrTdns',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 13841,
    specs: {
      detailsNumber: 155,
      size: {
        x: 205,
        y: 280,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  LIZARD: {
    key: 'LIZARD',
    name: 'Искусная ящерица',
    images: getImages('lizard'),
    imagesSmall: getImages('lizard', '_380'),
    video: 'KOfeAN6b5vU',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 14575,
    specs: {
      detailsNumber: 140,
      size: {
        x: 200,
        y: 305,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  REINDEER: {
    key: 'REINDEER',
    name: 'Гордый северный олень',
    images: getImages('reindeer'),
    imagesSmall: getImages('reindeer', '_380'),
    video: 'JGE-Cs4TU74',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 14797,
    specs: {
      detailsNumber: 136,
      size: {
        x: 205,
        y: 290,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  EAGLE: {
    key: 'EAGLE',
    name: 'Золотой беркут',
    images: getImages('eagle'),
    imagesSmall: getImages('eagle', '_380'),
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 14951,
    specs: {
      detailsNumber: 150,
      size: {
        x: 205,
        y: 220,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  TIGER: {
    key: 'TIGER',
    name: 'Амурский тигр',
    images: getImages('tiger'),
    imagesSmall: getImages('tiger', '_380'),
    video: 'G4TQCpqYs98',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 14972,
    specs: {
      detailsNumber: 150,
      size: {
        x: 205,
        y: 215,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
  ARGALI: {
    key: 'ARGALI',
    name: 'Горный баран',
    images: getImages('argali'),
    imagesSmall: getImages('argali', '_380'),
    video: '86BY5fVPuJI',
    price: { [REGIONS.RU]: PUZZLE_PRICE_RUB, [REGIONS.BY]: PUZZLE_PRICE_BY },
    discountPrice: {
      [REGIONS.RU]: PUZZLE_DISCOUNT_PRICE_RUB,
      [REGIONS.BY]: PUZZLE_DISCOUNT_PRICE_BY,
    },
    offer_id: 15283,
    specs: {
      detailsNumber: 143,
      size: {
        x: 205,
        y: 250,
        z: 4,
      },
      composition: 'puzzles.bison.composition',
    },
  },
};

export const STAND = {
  price: { [REGIONS.RU]: STAND_PRICE_RUB, [REGIONS.BY]: STAND_PRICE_BY },
  offer_id: 11345,
};

export const PACKAGE_SIZES = {
  x: 200,
  y: 160,
  z: 45,
};

export const GALLERY_IMAGES = [
  { src: '/images/gallery/1.jpg', alt: 'gallery-1' },
  { src: '/images/gallery/2.jpg', alt: 'gallery-2' },
  { src: '/images/gallery/3.jpg', alt: 'gallery-3' },
  { src: '/images/gallery/4.jpg', alt: 'gallery-4' },
  { src: '/images/gallery/5.jpg', alt: 'gallery-5' },
  { src: '/images/gallery/6.jpg', alt: 'gallery-6' },
  { src: '/images/gallery/7.jpg', alt: 'gallery-7' },
  { src: '/images/gallery/8.jpg', alt: 'gallery-8' },
  { src: '/images/gallery/9.jpg', alt: 'gallery-9' },
  { src: '/images/gallery/10.jpg', alt: 'gallery-10' },
  { src: '/images/gallery/11.jpg', alt: 'gallery-11' },
  { src: '/images/gallery/12.jpg', alt: 'gallery-12' },
  { src: '/images/gallery/13.jpg', alt: 'gallery-13' },
  { src: '/images/gallery/14.jpg', alt: 'gallery-14' },
  { src: '/images/gallery/15.jpg', alt: 'gallery-15' },
  { src: '/images/gallery/16.jpg', alt: 'gallery-16' },
];

export const COMMERCIAL_IMAGES = [
  { src: '/images/commercial/belarus_box.png', alt: 'belarus_box' },
  { src: '/images/commercial/belarus_puzzle_with_box.png', alt: 'belarus_puzzle_with_box' },
  { src: '/images/commercial/bizon_with_box.png', alt: 'bizon_with_box' },
  { src: '/images/commercial/bizon.png', alt: 'custom_bizon' },
];

export const TESTIMONIALS_IMAGES = [
  { src: '/images/testimonials/1.png', alt: 'testimonial-1', number: 7 },
  { src: '/images/testimonials/2.png', alt: 'testimonial-2', number: 8 },
  { src: '/images/testimonials/3.png', alt: 'testimonial-3', number: 9 },
  { src: '/images/testimonials/4.png', alt: 'testimonial-4', number: 1 },
  { src: '/images/testimonials/5.png', alt: 'testimonial-5', number: 2 },
  { src: '/images/testimonials/6.png', alt: 'testimonial-6', number: 3 },
  { src: '/images/testimonials/7.png', alt: 'testimonial-7', number: 4 },
];

export const SECTIONS = [
  { name: 'Главная', id: 'intro' },
  { name: 'Уникальность пазла', id: 'unique' },
  { name: 'Каталог', id: 'catalog' },
  { name: 'Фотогалерея', id: 'gallery' },
  { name: 'Подставки', id: 'stand' },
  { name: 'Отзывы', id: 'testimonials' },
  { name: 'История пазла', id: 'history' },
  { name: 'Как сделать заказ', id: 'howTo' },
  { name: 'Коммерческое предложение', id: 'commercial' },
  { name: 'Контакты', id: 'footer' },
];

export const LINKS = {
  INST: 'https://www.instagram.com/touchwoodby/',
  VK: 'https://vk.com/touchwoodby',
  EMAIL: 'mailto:touchwoodby@mail.ru',
  PHONE: 'tel:+375292102999',
};
