import { writable } from 'svelte/store';

import { disableBodyScroll, enableBodyScroll } from '../utils';

const modals = writable({
  visible: {},
  payloads: {},
});

export function openModal(id, payload) {
  modals.update((state) => ({
    ...state,
    visible: { ...state.visible, [id]: true },
    payloads: { ...state.payloads, [id]: payload },
  }));
  disableBodyScroll();
}

export function closeModal(id) {
  modals.update((state) => ({
    ...state,
    visible: { ...state.visible, [id]: false },
    payloads: { ...state.payloads, [id]: null },
  }));
  enableBodyScroll();
}

export default modals;
