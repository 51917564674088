<script>
  import { onDestroy, onMount } from 'svelte';
  import qs from 'qs';

  import Header from './Header.svelte';
  import Intro from './sections/Intro.svelte';
  import Unique from './sections/Unique.svelte';
  import Catalog from './sections/Catalog.svelte';
  import Gallery from './sections/Gallery.svelte';
  import Stand from './sections/Stand.svelte';
  import Testimonials from './sections/Testimonials.svelte';
  import History from './sections/History.svelte';
  import HowToOrder from './sections/HowToOrder.svelte';
  import Commercial from './sections/Commercial.svelte';
  import Footer from './Footer.svelte';
  import ModalsContainer from './modals/ModalsContainer.svelte';
  import modals, { openModal } from '../store/modals';
  import regionStore from '../store/region';
  import apiService from '../apiService';
  import { MODAL_IDS, REGIONS } from '../constants/inner';
  import { PUZZLES } from '../constants/content';

  let animationStopper = false;
  let windowWidth = window.innerWidth;
  let resizeTimer;

  onMount(() => {
    const { region, success, purchase } = qs.parse(window.location.search.slice(1));

    if (purchase) {
      openModal(MODAL_IDS.BUY, {
        init: localStorage.getItem('active-puzzle') || PUZZLES.BISON.key,
      });
    }

    if (success) {
      openModal(MODAL_IDS.SUCCESS);
    }

    getCountry(region);
    window.addEventListener('resize', resizeListener);
  });

  onDestroy(() => {
    window.removeEventListener('resize', resizeListener);
  });

  async function getCountry(region) {
    try {
      if (region && region === REGIONS.RU) {
        regionStore.set(region);
      } else {
        const countryCode = await apiService.getGeolocation();

        if (countryCode === REGIONS.RU) {
          regionStore.set(countryCode);
        }
      }
    } catch (e) {}
  }

  function resizeListener() {
    windowWidth = window.innerWidth;
    animationStopper = true;
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      animationStopper = false;
    }, 400);
  }
</script>

<div class="global">
  <Header {windowWidth} />
  <main class:with-modal={Object.values($modals.visible).some((visible) => visible)}>
    <Intro {windowWidth} />
    <Unique />
    <Catalog {windowWidth} />
    <Gallery {windowWidth} />
    <Stand {windowWidth} />
    <Testimonials {windowWidth} {animationStopper} />
    <History />
    <HowToOrder />
    <Commercial {windowWidth} />
  </main>
  <Footer {windowWidth} />
</div>
<ModalsContainer {windowWidth} />

<style global lang="scss">
  @import '../variables.scss';

  .global {
    position: relative;
  }

  main {
    background-color: $light-color;
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    .with-modal {
      width: calc(100% + 13px);
    }
  }
</style>
