<script>
  import Button from './common/Button.svelte';
  import { BUTTON_TYPES, MODAL_IDS, REGIONS } from '../constants/inner';
  import { LINKS } from '../constants/content';
  import { openLink } from '../utils';
  import modals, { openModal } from '../store/modals';
  import Switch from './common/Switch.svelte';
  import region from '../store/region';

  export let windowWidth;
  let switchValue;

  $: switchValue = $region === REGIONS.RU;
  $: region.set(switchValue ? REGIONS.RU : REGIONS.BY);
</script>

<header class:with-modal={Object.values($modals.visible).some((visible) => visible)}>
  <img class="logo" alt="logo" src="/images/logo.svg" />
  <div class="header-content">
    <div class="contacts">
      {#if windowWidth > 900}<span class="phone font-18">+375 29 210 2999</span>{/if}

      <span class="links font-18">
        {#if windowWidth <= 900}
          <Button ref="header-link" on:click={() => openLink(LINKS.PHONE)} type={BUTTON_TYPES.ICON}>
            <img class="icon" alt="instagram" src="/images/phone.svg" />
          </Button>
        {/if}
        <Button ref="header-link" on:click={() => openLink(LINKS.INST)} type={BUTTON_TYPES.ICON}>
          <img class="icon" alt="instagram" src="/images/inst.svg" />
        </Button>

        {#if windowWidth >= 600}
          <Button ref="header-link" on:click={() => openLink(LINKS.VK)} type={BUTTON_TYPES.ICON}>
            <img class="icon" alt="vk" src="/images/vk.svg" />
          </Button>
        {/if}

        <Button ref="header-link" on:click={() => openLink(LINKS.EMAIL)} type={BUTTON_TYPES.ICON}>
          <img class="icon" alt="email" src="/images/email.svg" />
        </Button>
      </span>
      <div class="separator" />
      <Switch label={$region} bind:checked={switchValue} />
    </div>
    <Button ref="burger" on:click={() => openModal(MODAL_IDS.NAVIGATION)} type={BUTTON_TYPES.ICON}>
      <img class="burger" alt="menu" src="/images/burger.svg" />
    </Button>
  </div>
</header>

<style lang="scss">
  @import '../variables.scss';

  header {
    width: calc(100% - 30rem);
    padding: 0.5rem 15rem;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 99;
  }

  header.with-modal {
    padding-right: calc(15rem + 15px);
  }

  span {
    font-weight: 500;
    color: #998174;
  }

  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contacts {
    border-radius: 40px;
    background-color: rgba(250, 233, 218, 0.8);
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .separator {
    width: 2px;
    height: 2.5rem;
    margin: 0 3rem;
    background-color: $disabled-color;
  }

  .phone {
    margin-right: 9rem;
    color: #998174;
  }

  .icon {
    height: auto;
    max-width: 25px;
    width: 100%;
  }

  .links {
    display: flex;
  }

  :global([ref='header-link']) {
    margin-left: 30px;
  }

  .burger {
    width: 6rem;
  }

  :global([ref='burger']) {
    margin-left: 43px;
  }

  .logo {
    width: 18.9rem;
    height: 7.6rem;
    margin-right: 30px;
  }
  @media screen and (max-width: 900px) {
    :global([ref='header-link']:first-child) {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 600px) {
    header {
      padding: 0.5rem 1.5rem;
      width: calc(100% - 3rem);
    }
    .contacts {
      padding: 7px 13px;
    }

    .separator {
      height: 2rem;
      margin: 0 1rem;
      background-color: $disabled-color;
    }
    .icon {
      width: 100%;
      max-width: 30px;
    }

    header.with-modal {
      padding-right: 1.5rem;
    }

    .logo {
      width: 9.3rem;
      height: 4rem;
      margin-right: 2rem;
    }

    :global([ref='header-link']) {
      margin-left: 1.2rem;
    }

    .burger {
      width: 3.2rem;
    }

    :global([ref='burger']) {
      margin-left: 15px;
    }
  }
</style>
