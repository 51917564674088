<script>
  import { SwiperSlide } from 'swiper/svelte';

  import { PUZZLES, STAND } from '../../../constants/content';
  import { closeModal } from '../../../store/modals';
  import { BUTTON_TYPES, COLORS, MODAL_IDS, MODAL_TYPES } from '../../../constants/inner';
  import Modal from '../../common/Modal.svelte';
  import PuzzleBuyingLot from '../../common/PuzzleBuyingLot.svelte';
  import Checkbox from '../../common/Checkbox.svelte';
  import BuyForm from './BuyForm.svelte';
  import SwiperCarousel from '../../common/SwiperCarousel.svelte';
  import LongArrow from '../../icons/LongArrow.svelte';
  import Button from '../../common/Button.svelte';
  import region from '../../../store/region';
  import { getCurrency } from '../../../utils';

  export let windowWidth;
  export let visible = false;
  export let init = '';

  let swiper;
  const products = Object.entries(PUZZLES);
  let orderData = {};

  $: selected = init;
  $: {
    if (swiper) {
      const index = products.findIndex(([key]) => key === init);

      if (index >= 0 && swiper.slides) {
        [selected] = products[index];
        swiper.slideTo(index + 1);
      }
    }
  }
  $: {
    if (init) {
      orderData[init].quantity = 1;
    }
  }
  $: active = products.findIndex(([key]) => key === selected);
  $: checkBoxValue = orderData[selected]?.withStand;
  $: {
    if (!visible) {
      orderData = initOrder();
    }
  }

  function initOrder() {
    return Object.fromEntries(
      products.map(([key, { discountPrice, offer_id }]) => [
        key,
        { quantity: 0, withStand: false, price: discountPrice[$region], offer_id },
      ]),
    );
  }

  function onChange(event) {
    const activeSlide = event.detail[0][0].activeIndex - 1;

    if (activeSlide === products.length || activeSlide === -1) {
      active = 0;
    } else {
      active = activeSlide;
    }

    [selected] = products[active];
  }

  function onQuantityChange(accumulator) {
    return function quantityChangeHandler() {
      orderData[selected].quantity += accumulator;

      if (!orderData[selected].quantity) {
        orderData[selected].withStand = false;
      }
    };
  }

  function onCheckboxClick() {
    const activePuzzleData = orderData[selected];

    orderData[selected].withStand = !activePuzzleData.withStand;
  }
</script>

{#if visible}
  <Modal
    closeColor={COLORS.ACCENT}
    close={() => closeModal(MODAL_IDS.BUY)}
    type={MODAL_TYPES.LARGE}
  >
    <div class="container">
      <div class="content">
        <div class="left">
          <h3>{products[active][1].name}</h3>
          <div class="carousel">
            <SwiperCarousel bind:swiper {onChange} slidesPerView={1}>
              <span class="control" slot="left-control"><LongArrow color={COLORS.DISABLED} /></span>

              {#each products as [key, { name, price, discountPrice, imagesSmall, images }] (key)}
                <SwiperSlide>
                  <PuzzleBuyingLot
                    price={`${price[$region]} ${getCurrency($region)}`}
                    discountPrice={`${discountPrice[$region]} ${getCurrency($region)}`}
                    imageAlt={windowWidth < 600 ? imagesSmall[0].alt : images[0].alt}
                    image={windowWidth < 600 ? imagesSmall[0].src : images[0].src}
                  />
                </SwiperSlide>
              {/each}

              <span class="control" slot="right-control"><LongArrow color={COLORS.DISABLED} /></span
              >
            </SwiperCarousel>
          </div>

          <div class="controls">
            <Button
              disabled={orderData[selected].quantity === 0}
              ref="count-button"
              on:click={onQuantityChange(-1)}
              type={BUTTON_TYPES.CIRCLE}
            >
              -
            </Button>
            <span class="count font-24"
              >{orderData[selected].quantity < 10
                ? `0${orderData[selected].quantity}`
                : orderData[selected].quantity}</span
            >
            <Button ref="count-button" on:click={onQuantityChange(1)} type={BUTTON_TYPES.CIRCLE}
              >+</Button
            >
          </div>

          {#if orderData[selected].quantity}
            <div class="stand">
              <div class="stand-control">
                <Checkbox on:change={onCheckboxClick} value={checkBoxValue} />
                <span on:click={onCheckboxClick} class="font-24 stand-checkbox-label"
                  >Подставка к пазлу</span
                >
              </div>
              <span class="stand-price font-18"
                >+ {STAND.price[$region]} {getCurrency($region)}</span
              >
            </div>
          {/if}
        </div>
        <div class="right">
          <BuyForm {windowWidth} {orderData} />
        </div>
      </div>
    </div>
  </Modal>
{/if}

<style lang="scss">
  @import '../../../variables.scss';
  .container {
    height: 100%;
  }

  h3 {
    color: $accent-color;
    margin-bottom: 20px;
  }

  .content {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
  }

  :global([ref='navigation-close']) {
    position: absolute;
    right: 149px;
    top: 65px;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 4.5vw;
    width: 45%;
  }

  .carousel {
    width: 100%;
    height: 55rem;
  }

  .count {
    width: 40px;
    text-align: center;
    color: $accent-color;
    padding: 5px 30px;
    margin: 0px 11px;
    border-radius: 100px;
    border: 1px solid $accent-color;
  }

  .controls {
    display: flex;
    align-items: center;
  }

  .stand {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  .stand-control {
    display: flex;
    align-items: center;
  }

  .stand-checkbox-label {
    margin-left: 10px;
    font-weight: 500;
    cursor: pointer;
  }

  .stand-price {
    color: $disabled-color;
    margin-top: 4px;
  }

  @media screen and (max-width: 850px) {
    .container {
      overflow-y: auto;
      height: calc(100vh - 5%);
    }
    .content {
      flex-direction: column;
      padding-top: 5%;
      justify-content: space-between;
      height: auto;
      align-items: center;
    }

    .carousel {
      height: auto;
    }

    .left {
      width: 90%;
    }

    .right {
      margin-top: 5rem;
    }
  }
  @media screen and (max-width: 600px) {
    .stand {
      margin: 2rem 0px;
    }
    .right {
      margin-top: 3rem;
    }
    .count {
      width: 23px;
      padding: 1px 20px;
    }

    h3 {
      margin-bottom: 0px;
    }
  }
</style>
