<script>
  export let name;
  export let placeholder;
  export let value;
  export let validate = () => {};
  let input;
  let error;
  let isFocused = false;

  function onBlur() {
    error = validate(value);
    isFocused = false;
  }

  function onFocus() {
    isFocused = true;
  }
</script>

<style lang="scss">
  @import '../../variables.scss';

  div {
    position: relative;
    width: 300px;
    height: 68px;
  }

  input,
  span {
    font-size: 18px;
    line-height: 22px;
  }

  input {
    width: calc(100% - 60px);
    height: calc(100% - 46px);
    padding: 21.5px 30px;
    border-radius: 100px;
    background-color: $light-color;
    border: 1px solid $accent-color;
    color: $accent-color;
  }

  span {
    position: absolute;
    color: $disabled-color;
    transition: transform cubic-bezier(0.445, 0.05, 0.55, 0.95) 100ms;
    transform: translate(25px, -45px);
  }

  input:focus {
    outline: none;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
  }

  .error {
    border-color: $error-color;
  }

  .error-text {
    font-size: 12px;
    line-height: 14px;

    transform: translate(165px, -55px);
    color: $error-color;
  }

  @media screen and (max-width: 600px) {
    div {
      width: 240px;
      height: 44px;
    }

    input {
      width: calc(100% - 40px);
      height: calc(100% - 38px);
      padding: 17.5px 20px;
      border-radius: 750px;
    }

    span {
      transform: translate(20px, -34px);
    }

    .error-text {
      transform: translate(115px, -35px);
    }
  }
</style>

<div>
  <input bind:this={input} class:error bind:value on:blur={onBlur} on:focus={onFocus} {name} />
  {#if !value && !isFocused}
    <span on:click={() => input.focus()} class:error-text={error}>{error || placeholder}</span>
  {/if}
</div>
