<script>
  import { SwiperSlide } from 'swiper/svelte';

  import { GALLERY_IMAGES } from '../../constants/content';
  import { COLORS, PAGER_TYPES } from '../../constants/inner';
  import Image from '../common/Image.svelte';
  import Pager from '../common/Pager.svelte';
  import SwiperCarousel from '../common/SwiperCarousel.svelte';
  import LongArrow from '../icons/LongArrow.svelte';

  export let windowWidth;

  let active = 0;

  function onChange(event) {
    const activeSlide = event.detail[0][0].activeIndex - 1;

    if (activeSlide === GALLERY_IMAGES.length || activeSlide === -1) {
      active = 0;
    } else {
      active = activeSlide;
    }
  }
</script>

<section id="gallery">
  <h1>Фотогалерея</h1>

  <div class="container">
    <div class="carousel-container">
      <SwiperCarousel
        {onChange}
        slidesPerView={windowWidth > 800 ? Math.ceil(windowWidth / 750) : 1}
      >
        <span slot="left-control"><LongArrow color={COLORS.DISABLED} /></span>

        {#each GALLERY_IMAGES as { src, alt } (alt)}
          <SwiperSlide>
            <div class="slide-content">
              <Image {alt} {src} />
            </div>
          </SwiperSlide>
        {/each}

        <span slot="right-control"><LongArrow color={COLORS.DISABLED} /></span>
      </SwiperCarousel>
    </div>

    <div class="pager">
      <Pager active={active + 1} total={GALLERY_IMAGES.length} type={PAGER_TYPES.HORIZONTAL} />
    </div>
  </div>
</section>

<style type="text/scss">
  @import '../../variables.scss';

  section {
    height: 100%;
    padding: 20px 79px;
    width: calc(100vw - 158px);
  }

  h1 {
    padding: 10rem 0;
    color: $accent-color;
  }

  .pager {
    width: 80%;
    margin-top: 5rem;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slide-content {
    max-width: 100%;
    height: 60rem;
    display: flex;
    align-items: center;
  }

  .carousel-container {
    width: 100%;
  }

  @media screen and (max-width: 950px) {
    .pager {
      width: 70%;
    }

    .slide-content {
      margin-right: 0px;
    }
  }

  @media screen and (max-width: 600px) {
    h1 {
      padding: 3rem 0;
    }

    section {
      height: 100%;
      padding: 0px 9px;
      width: calc(100vw - 18px);
    }

    .pager {
      width: 75%;
    }

    .slide-content {
      height: 18rem;
    }

    .carousel-container {
      width: 90%;
    }
  }
</style>
