<script>
  import { fade } from 'svelte/transition';

  export let value;
</script>

<style lang="scss">
  @import '../../variables.scss';

  .checkbox {
    width: 27px;
    height: 27px;
    position: relative;
    cursor: pointer;
  }

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .container {
    position: relative;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px solid $accent-color;
    transition: background-color ease-in-out 200ms;
  }

  .checked {
    background-color: $accent-color;
  }

  .mark {
    position: absolute;
    top: 6px;
    left: 3px;
  }
</style>

<div class="checkbox">
  <div class="container" class:checked={value}>
    {#if value}
      <svg
        transition:fade={{ duration: 200 }}
        class="mark"
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999961 6.33197L7.337 12.6689" stroke="#FFF6EE" stroke-width="1.5" />
        <path d="M6.6488 12.6484L17.2972 2" stroke="#FFF6EE" stroke-width="1.5" />
      </svg>
    {/if}
  </div>
  <input {value} type="checkbox" id="toggle" on:change />
</div>
