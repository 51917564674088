<script>
  export let className;
</script>

<svg
  class={className}
  width="225"
  height="225"
  viewBox="0 0 225 225"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M218.566 30.7595C215.256 27.4438 209.886 27.4381 206.576 30.7454L104.846 132.207L68.1416 92.3431C64.9701 88.9004 59.6079 88.6772 56.1595 91.8483C52.7137 95.0198 52.4931 100.385 55.6647 103.83L98.347 150.184C99.9101 151.883 102.098 152.87 104.404 152.918C104.466 152.92 104.526 152.92 104.585 152.92C106.827 152.92 108.984 152.03 110.572 150.447L218.55 42.7526C221.868 39.4457 221.874 34.0751 218.566 30.7595Z"
    fill="#40261D" />
  <path
    d="M216.52 104.02C211.836 104.02 208.04 107.816 208.04 112.5C208.04 165.183 165.183 208.04 112.5 208.04C59.8201 208.04 16.9598 165.183 16.9598 112.5C16.9598 59.8201 59.8201 16.9598 112.5 16.9598C117.184 16.9598 120.98 13.1638 120.98 8.48013C120.98 3.796 117.184 0 112.5 0C50.4668 0 0 50.4668 0 112.5C0 174.531 50.4668 225 112.5 225C174.531 225 225 174.531 225 112.5C225 107.816 221.204 104.02 216.52 104.02Z"
    fill="#40261D" />
</svg>
